import React, { Component } from 'react'
import { connect } from 'react-redux'

import { 
    BuybackChoices,
    updateBuybackChoiceThunk,
    PlayerStatuses,
    updateEntryStatusThunk,
    SET_PICK_BUTTON_VISIBILITY,
    PickButtonVisibilities
} from '../../actions/actions'
import { SET_SHOW_BUYBACK_MODAL } from '../../actions/settings'

import '../../css/buybackmodal.css'

const Headings = {
    MAKE_SELECTION: "Make Buyback Selection",
    SUBMITTED_YES: "Buyback Request Saved",
    CONFIRM_NO: "Are you sure?",
    SUBMITTED_NO: "Thank You For Playing",
    IGNORE: "Buyback Selection Postponed"
}

const MainButtonText = {
    BUYBACK_YES: "Yes, I will buy back in",
    CONFIRM_NO: "Eliminate my entry",
    CLOSE: "Close"
}

class BuybackModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            heading: Headings.MAKE_SELECTION,
            mainButtonText: MainButtonText.BUYBACK_YES,
            choiceMade: false
        }
    }

    handleDecideLater() {
        this.props.updateBuybackChoiceThunk({ // set entry's buybackChoice to IGNORE
            buybackChoice: BuybackChoices.IGNORE,
            userEntry: this.props.currentEntry,
            year: this.props.year
        })
        this.setState({ heading: Headings.IGNORE, mainButtonText: MainButtonText.CLOSE, choiceMade: true })
    }

    handleBuybackNo() {
        if (this.state.heading === Headings.MAKE_SELECTION) { // this is the first time, so ask user if they're sure
            this.setState({ heading: Headings.CONFIRM_NO, mainButtonText: MainButtonText.CONFIRM_NO })
        } else if (this.state.heading === Headings.CONFIRM_NO) { // the user confirmed no, so eliminate the entry
            this.props.updateBuybackChoiceThunk({ // set entry's buybackChoice to NO
                buybackChoice: BuybackChoices.NO,
                userEntry: this.props.currentEntry,
                year: this.props.year
            })

            this.props.updateEntryStatusThunk({ // update entry's status to ELIMINATED
                status: PlayerStatuses.ELIMINATED.toUpperCase(),
                userEntry: this.props.currentEntry,
                year: this.props.year,
                weekEliminated: this.props.currentWeek,
            })

            this.setState({ heading: Headings.SUBMITTED_NO, mainButtonText: MainButtonText.CLOSE, choiceMade: true })
        }
    }

    handleBuybackYes() {
        this.props.updateBuybackChoiceThunk({ // set entry's buybackChoice to YES
            buybackChoice: BuybackChoices.YES,
            userEntry: this.props.currentEntry,
            year: this.props.year
        })

        this.setState({ heading: Headings.SUBMITTED_YES, mainButtonText: MainButtonText.CLOSE, choiceMade: true })
    }

    handleMainButtonClicked() {
        console.log("handleMainButtonClicked")
        if (this.state.heading === Headings.MAKE_SELECTION)
            this.handleBuybackYes()
        else if (this.state.heading === Headings.CONFIRM_NO)
            this.handleBuybackNo()
        else { // close the modal
            this.resetState()
            this.props.setShowBuybackModal(false)
            if (this.state.heading !== Headings.SUBMITTED_YES)
                this.props.setPickButtonVisibility(PickButtonVisibilities.NONE)
        }
    }

    resetState() {
        this.setState({
            heading: Headings.MAKE_SELECTION,
            mainButtonText: MainButtonText.BUYBACK_YES,
            choiceMade: false
        })
    }

    renderMessage(entryName) {
        if (this.state.heading === Headings.MAKE_SELECTION) {
            return <>
                <p>You suffered a loss for your entry <strong>{entryName}</strong>, but the good news is your pool offers a one-time optional buyback per entry for <strong>${(this.props.buybackFee).toFixed(2)}</strong>.</p><br/>
                <p>If you buy back in, you will be allowed to continue making picks. If not, you will be eliminated and your season will be over for this entry.</p><br/>
            </>
        } else if (this.state.heading === Headings.CONFIRM_NO) {
            return <>
                <p>Are you sure you do <strong>NOT</strong> want to buy back in? By choosing <strong>{MainButtonText.CONFIRM_NO}</strong>, you agree to eliminate this entry and will no longer be able to submit picks.</p><br/>
                <p><strong className="buyback_message">This will eliminate your entry and CANNOT be undone.</strong></p><br/>
                <p>You can click the <strong>Go Back</strong> button below to change your selection.</p><br/>
            </>
        }else if (this.state.heading === Headings.SUBMITTED_NO) {
            return <>
                <p>Your selection has been saved.</p>
                <p>We hope you enjoyed your survivor pool and using Survivin' Pool Manager this year.</p>
            </>
        } else if (this.state.heading === Headings.SUBMITTED_YES) {
            return <>
                <p>Your selection has been saved.</p>
                <p>Your pool manager will provide information regarding payment. You are still alive and can continue to submit picks.</p>
            </>
        } else { // heading is IGNORE
            return <p>Next time you make a pick, you will be reminded to make your buyback selection, at which point you must decide in order to submit a new pick.</p>
        }
    }

    render() {
        let currentEntryObj = this.props.entries.filter(e => e.userEntry === this.props.currentEntry)[0]
        let showBuybackModal = false
        if (currentEntryObj && this.props.entries && this.props.entries.length > 0)
            showBuybackModal = ((currentEntryObj.buybackChoice === BuybackChoices.PENDING) || this.state.choiceMade) // if the state has already been updated from pending, we still want to see the modal if a choice has been made

        if (!this.props.showBuybackModal && !showBuybackModal)
            return <></>
        else
            return (
                <div id="buyback_modal">
                    <div id="buyback_modal_content">
                        <h2>{this.state.heading}</h2><br/>
                        {this.renderMessage(currentEntryObj.entryName)}
                        <div id="btn_modal_container">
                            {!(this.state.mainButtonText === MainButtonText.CLOSE || this.state.mainButtonText === MainButtonText.CONFIRM_NO) && <button className="btn_plain btn_modal" id="btn_buyback_cancel" onClick={() => this.handleDecideLater()}>I'll decide later</button>}
                            {this.state.mainButtonText === MainButtonText.CONFIRM_NO && <button className="btn_plain btn_modal" id="btn_buyback_cancel" onClick={() => this.resetState()}>Go Back</button>}
                            <button className="btn_accent btn_modal" id="btn_buyback_yes" onClick={() => this.handleMainButtonClicked()}>{this.state.mainButtonText}</button>
                            {!(this.state.mainButtonText === MainButtonText.CLOSE || this.state.mainButtonText === MainButtonText.CONFIRM_NO) && <button className="btn_plain btn_modal" id="btn_buyback_no" onClick={() => this.handleBuybackNo()}>No, I will <strong>NOT</strong> buy back in</button>}
                        </div>
                    </div>
                </div>
            )
    }
}

// ------- Redux -------
function mapStateToProps(state) {
    return {
        showBuybackModal: state.showBuybackModal,
        entries: state.entries,
        currentEntry: state.currentEntry,
        buybackFee: state.buybackFee,
        currentSeason: state.currentSeason,
        userName: state.userName,
        year: state.year,
        currentWeek: state.currentWeek,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateBuybackChoiceThunk: (response) => dispatch(updateBuybackChoiceThunk( response )),
        updateEntryStatusThunk: (response) => dispatch(updateEntryStatusThunk( response )),
        setShowBuybackModal: (response) => dispatch({ type: SET_SHOW_BUYBACK_MODAL, response }),
        setPickButtonVisibility: (response) => dispatch({ type: SET_PICK_BUTTON_VISIBILITY, response })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BuybackModal)