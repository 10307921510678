import React, { Component } from 'react'
import { connect } from 'react-redux'

import axios from 'axios'
import _ from 'lodash'
import { isMobile } from 'react-device-detect'

import Header from '../Picks/Header'
import EntryBar from '../Picks/EntryBar'
import PicksSidebar from '../Picks/PicksSidebar'
import GamesList from '../Picks/GamesList'
import SubmitModal from '../Modals/SubmitModal'
import CreateEntriesModal from '../Modals/CreateEntriesModal'
import BuybackModal from '../Modals/BuybackModal'
import PoolViewContainer from '../Pool/PoolViewContainer'
import HamburgerMenu from './HamburgerMenu'
import InfoPage from '../Info/InfoPage'
import AboutPage from '../About/AboutPage'

import {
    ADD_ENTRY,
    SET_ENTRY_FEE,
    SET_BUYBACK_ALLOWED,
    SET_BUYBACK_FEE,
    SET_MANAGER_EMAIL,
    SET_ADMIN_EMAIL,
    SET_SIGNUP_DEADLINE,
    CLEAR_ENTRY_DATA,
    CLEAR_PICK_DATA, 
    ADD_PICKS,
    SET_POOL_WINNERS
} from '../../actions/actions'
import { 
    Themes,
    Pages,
    ADD_SUBMIT_DEADLINE,
    SET_TEAMS_PER_WEEK,
    SET_RESUBMIT_ALLOWED,
    SET_MAX_ENTRIES_PER_USER,
    SET_PAYMENT_STRING
} from '../../actions/settings'

class Dashboard extends Component {
    constructor(props) {
        super(props)
        this.loadAllDynamicData = this.loadAllDynamicData.bind(this)
        this.state = { 
            isReady: false,
            isFirstTime: false
        }
    }
    
    componentDidMount() {
        // these don't change in real time and can be loaded once
        const promisePoolData = this.loadPoolData()

        // refresh every 10 minutes to see if there's any new data in DB to pull in
        Promise.all([promisePoolData]).then(() => {
            this.loadAllDynamicData()
            setInterval(this.loadAllDynamicData, 600000)
        })
    }

    async loadAllDynamicData() {
        try {
            this.setState({ isReady: false })
            const promiseEntry = this.loadEntryData()
            const promisePick = this.loadPickData()
        
            Promise.all([promiseEntry, promisePick]).then(() => {
                this.setState({ isReady: true })
            })
        } catch (e) {
            console.log(e)
        }
    }

    loadPoolData() {
        return (       
            axios.get("/api/pools", { params: { poolId: this.props.poolId }})
            .then((response) => {
                this.props.setEntryFee(response.data[0].entryFee)
                this.props.setBuybackAllowed(response.data[0].buybackAllowed)
                this.props.setBuybackFee(response.data[0].buybackFee)
                this.props.setTeamsPerWeek(response.data[0].teamsPerWeek)
                this.props.setSignupDeadline(response.data[0].signupDeadline)
                this.props.setAdminEmail(response.data[0].adminEmail)
                this.props.setResubmitAllowed(response.data[0].resubmitAllowed)
                this.props.setMaxEntriesPerUser(response.data[0].maxEntriesPerUser)
                this.props.setPaymentString(response.data[0].paymentString)
                this.props.setPoolWinners(String(this.props.year) in response.data[0].poolWinners ? response.data[0].poolWinners[this.props.year] : null)
            })
            .catch(() => { console.log('Pool data load - Internal server error') })
        )
    }

    loadEntryData() {
        this.props.clearEntryData()
        return (
            axios.get("/api/entries", { params: { year: this.props.year }})
            .then((response) => {
                let entries = _.sortBy(response.data, ['entryName']) // use lodash to order by entryName
                if (entries.length === 0) {
                    this.setState({ isFirstTime: true })
                } else  {
                    entries.map(e =>
                        this.props.addEntry({
                            sUserId: e.sUserId,
                            entryId: e.entryId, 
                            userEntry: e.userEntry,
                            entryName: e.entryName,
                            userName: e.userName,
                            status: e.status,
                            buybackChoice: e.buybackChoice
                        })
                    )
                }
            })
            .catch(() => { console.log('Entries data load - Internal server error') })
        )
    }

    loadPickData() {
        this.props.clearPickData()
        return (
            axios.get("/api/get_user_picks", { params: { year: this.props.year }})
            .then((response) => {
                _.orderBy(response.data, ['season', 'userEntry', 'week'], ['desc', 'asc', 'asc']).map(t =>
                    this.props.addPicks({
                        season: t.season,
                        pickId: t.pickId, 
                        userEntry: t.userEntry, 
                        week: t.week, 
                        teams: t.teams, 
                        results: t.results,
                        teamGameTimes: t.teamGameTimes,
                        aggregatedResult: t.aggregatedResult,
                    })
                )
            })
            .catch(() => { console.log('Picks data load - Internal server error') })
        )
    }
    
    handleSetupCompletion() {
        this.setState({ isReady: false })
        const promiseEntry = this.loadEntryData()
        Promise.all([promiseEntry]).then(() => {
            this.setState({ isFirstTime: false, isReady: true })
        })
    }

    render() {
        const isDark = (this.props.theme === Themes.DARK)
        
        return (
            <div id="dashboard" className={isDark ? "darkTheme" : "lightTheme"}>
                <Header/>
                {isMobile && <HamburgerMenu/>}
                {!this.state.isReady ?
                    <div id="loading" className={isDark ? "darkTheme" : "lightTheme"}>
                        <img id="loading_spinner" src={isDark ? "/loading_dark.gif" : "/loading_light.gif"} alt="loading"/>
                    </div>
                    : (
                        (!this.props.page || this.props.page === Pages.MY_PICKS) ?
                            <>
                                <EntryBar/>
                                <PicksSidebar/> 
                                <GamesList/>
                                <SubmitModal/>
                                {this.state.isFirstTime && <CreateEntriesModal onSetupCompletion={() => this.handleSetupCompletion()}/>}
                                <BuybackModal/>
                            </>
                        : (this.props.page === Pages.MY_POOL) ?
                        <PoolViewContainer/> 
                        : (this.props.page === Pages.INFO) ?
                        <InfoPage/>
                        :
                        <AboutPage/>
                    )
                }              
            </div>
        )
    }
}

// ------- Redux --------
function mapStateToProps(state) {
    return {
        theme: state.theme,
        page: state.page,
        poolId: state.poolId,
        currentSeason: state.currentSeason,
        year: state.year
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addEntry: (entry) => dispatch({ type: ADD_ENTRY, entry }),
        addPicks: (response) => dispatch({ type: ADD_PICKS, response }),
        addSubmitDeadline: (response) => dispatch({ type: ADD_SUBMIT_DEADLINE, response }),
        setTeamsPerWeek: (response) => dispatch({ type: SET_TEAMS_PER_WEEK, response }),
        setEntryFee: (response) => dispatch({ type: SET_ENTRY_FEE, response }),
        setBuybackAllowed: (response) => dispatch({ type: SET_BUYBACK_ALLOWED, response }),
        setBuybackFee: (response) => dispatch({ type: SET_BUYBACK_FEE, response }),
        setManagerEmail: (response) => dispatch({ type: SET_MANAGER_EMAIL, response }),
        setSignupDeadline: (response) => dispatch({ type: SET_SIGNUP_DEADLINE, response }),
        clearEntryData: () => dispatch({ type: CLEAR_ENTRY_DATA }),
        clearPickData: () => dispatch({ type: CLEAR_PICK_DATA }), 
        setAdminEmail: (response) => dispatch({ type: SET_ADMIN_EMAIL, response }),
        setResubmitAllowed: (response) => dispatch({ type: SET_RESUBMIT_ALLOWED, response }),
        setMaxEntriesPerUser: (response) => dispatch({ type: SET_MAX_ENTRIES_PER_USER, response }),
        setPaymentString: (response) => dispatch({ type: SET_PAYMENT_STRING, response }),
        setPoolWinners: (response) => dispatch({ type: SET_POOL_WINNERS, response })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)