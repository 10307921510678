import React, { Component } from 'react'
import { connect } from 'react-redux'

import { isMobile } from 'react-device-detect'

import PickButtonContainer from './PickButtonContainer'

import { filterCurrentEntryPicksThisWeek, filterCurrentEntryPicks } from '../../utils'

import '../../css/game.css'
import { PickButtonVisibilities, Results } from '../../actions/actions'

class Game extends Component { 
    constructor(props) {
        super(props)
        this.state = { 
            pointSpread: ""
        }
    }

    componentDidMount() {
        if (this.props.currGame.HasStarted && !this.props.currGame.IsOver)
            this.setState({ pointSpread: "In Progress" })
        else if (this.props.currGame.IsOver && this.props.currGame.IsOvertime)
            this.setState({ pointSpread: "Final/OT" })
        else if (this.props.currGame.IsOver)
            this.setState({ pointSpread: "Final" })
        else {
            // TODO: if spreads need to be disabled, comment out this whole block. In future, add a flag for showSpreads
            
            let matches = this.props.spreads.filter(g => (g.awayTeam === this.props.currGame.AwayTeam &&
                                                    g.homeTeam === this.props.currGame.HomeTeam) )
            if (matches[0]) {
                let game = matches[0]
                if (parseFloat(game.homeSpread) < 0) // home team is favored
                    this.setState({ pointSpread: `${game.homeTeam} ${game.homeSpread}` })
                else if (parseFloat(game.awaySpread) < 0) // away team is favored
                    this.setState({ pointSpread: `${game.awayTeam} ${game.awaySpread}` })
                else // even
                    this.setState({ pointSpread: "EVEN" })
            }
        }   
    }

    getTeamWinsLosses(team) {
        if (this.props.standings.length === 0)
            return "0-0"
        
        for (let s in this.props.standings)
            if (team === this.props.standings[s].Team)
                return `${this.props.standings[s].Wins}-${this.props.standings[s].Losses}`
        
        return "0-0"
    }

    getTeamTies(team) {
        if (this.props.standings.length === 0)
            return ""
        
        for (let s in this.props.standings)
            if (team === this.props.standings[s].Team)
                return (this.props.standings[s].Ties === 0 ? "" : ("-" + this.props.standings[s].Ties))

        return ""
    }
      
    formatRecord(team) {
        var winsLosses = this.getTeamWinsLosses(team)
        var ties = this.getTeamTies(team)
        return winsLosses + ties
    }

    getDayOfWeekStringFromDay(day) {
        switch (day) {
            case 0: return "Sun"
            case 1: return "Mon"
            case 2: return "Tue"
            case 3: return "Wed"
            case 4: return "Thu"
            case 5: return "Fri"
            case 6: return "Sat"
            default: return ""
        }
    }

    // getCalibratedDayOfWeekStringFromDay(day) {
    //     switch (day) {
    //         case "0": return "Tue"
    //         case "1": return "Wed"
    //         case "2": return "Thu"
    //         case "3": return "Fri"
    //         case "4": return "Sat"
    //         case "5": return "Sun"
    //         case "6": return "Mon"
    //         default: return ""
    //     }
    // }

    formatDate() {
        if (this.props.currGame.Status === "Postponed")
            return "Postponed"
        
        let date = new Date(this.props.currGame.DateTimeUTC)
        date.setHours(date.getHours() - this.props.timezoneOffset)
        var day = this.getDayOfWeekStringFromDay(date.getDay())
        return day + " " + (date.getMonth() + 1) + '/' + date.getDate()
    }
      
    formatTime() {
        // const stdTimezoneOffset = () => {
        //     var jan = new Date(0, 1)
        //     var jul = new Date(6, 1)
        //     return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset())
        // }
        
        // const isDstObserved = (date) => {
        //     console.log(date.getTimezoneOffset())
        //     console.log(stdTimezoneOffset())
        //     return date.getTimezoneOffset() < stdTimezoneOffset()
        // }

        

        if (this.props.currGame.Status === "Postponed")
            return ""

        var time = new Date(this.props.currGame.DateTimeUTC)
        time.setHours(time.getHours() - this.props.timezoneOffset) // convert UTC to user time zone (24 hr)
      
        //console.log(isDstObserved(time))

        var isPM = time.getHours() >= 12 // while still in 24 hour mode, set isPM flag

        if (time.getHours() === 0) // display 0:00 as 12:00
            time.setHours(12)
        else if (time.getHours() > 12) // subtract 12 hours from anything 13:00 or more
            time.setHours(time.getHours() - 12)
        
        var mins = (time.getMinutes() < 10 ? '0' : '') + time.getMinutes() // display single-digit minutes with leading 0

        return time.getHours() + ":" + mins + (isPM ? " PM" : " AM")       
    }

    // get this week's picks for the current entry
    getThisWeeksPicks() {
        return filterCurrentEntryPicksThisWeek(this.props.picks, this.props.currentEntry, this.props.currentWeek, this.props.currentSeason)
    }

    // given a team, return a boolean representing whether team was picked (excludes pending picks)
    isPicked(team) {
        return filterCurrentEntryPicks(this.props.picks, this.props.currentEntry, this.props.currentSeason)
            .some(function(o) {
                let index = o["teams"].indexOf(team) // is -1 if team does not appear
                return index !== -1 && o["results"][index] !== Results.PENDING
            })
    }
  
    // in this app, we measure the start of the week as Tue === 0
    // calibrateDayOfWeek(day) {
    //     if (day >= 2 && day <= 6) // ex. Thu === 4 becomes Thu === 2
    //         return day - 2
    //     else if (day <= 1) // Sun === 0 becomes Sun === 5
    //         return day + 5
    // }

    formatDeadlineToPick() {
        let deadline = new Date(this.props.currGame.SubmitDeadlineUTC)

        let deadlineDOWString = this.getDayOfWeekStringFromDay(deadline.getDay())
        let displayDeadlineHour = parseInt(deadline.getHours(), 10)
        let displayDeadlineMinute =  parseInt(deadline.getMinutes(), 10)

        return <span>{deadlineDOWString + " " + (displayDeadlineHour > 12 ? displayDeadlineHour - 12: displayDeadlineHour) + ":" + (displayDeadlineMinute < 10 ? "0" : "") + displayDeadlineMinute + (displayDeadlineHour >= 12 ? " PM" : " AM")}</span>
    }

    hasDeadlinePassed() {
        if (this.props.currGame.HasStarted) // sanity check: if the game has started 
            return true
        else if (this.props.isOffseason) // if season has not yet started, don't apply submit deadlines
            return false
             
        // get the current date/time
        let currentDateTime = new Date() 

        // get the game's deadline
        let gameDeadline = new Date(this.props.currGame.SubmitDeadlineUTC) // convert to proper format to compare

        return currentDateTime > gameDeadline
    }

    isBothTeamsPicked() {
        let weekPicks = this.getThisWeeksPicks()
        let awayTeam = this.props.currGame.AwayTeam
        let homeTeam = this.props.currGame.HomeTeam

        // edge case: if this week's pick has already been submitted, don't show the game as unpickable
        // condition: if current week's pick submitted and (home team or away team is involved), then return and don't update
        if (weekPicks && weekPicks.length > 0) {
            for (let t in weekPicks[0].teams)
                if (weekPicks[0].teams[t] === awayTeam || weekPicks[0].teams[t] === homeTeam)
                    return false
        }
        
        return (this.isPicked(awayTeam) && this.isPicked(homeTeam))
    }
      
    isPickable() { 
        return (this.hasDeadlinePassed() || this.isBothTeamsPicked() || this.props.currGame.Status === "Postponed") ? false : true
    }

    // TODO: irrelevant for now bc recos is false; but eventually this needs to be rewritten to handle new point spread format
    formatPickString() {
        const awayTeam = this.props.currGame.AwayTeam
        const homeTeam = this.props.currGame.HomeTeam
        
        if (this.isBothTeamsPicked())
            return <span className="game_message">Both teams picked</span>
             
        // if this week's pick has already been submitted, show this
        let weekPicks = this.getThisWeeksPicks()
        for (let p in weekPicks) {
            if (weekPicks[p].week === this.props.currentWeek) {
                if (weekPicks[p].team === homeTeam)
                    return <span className="game_message"><strong>Your pick - {homeTeam}</strong> over {awayTeam}</span>
                else if (weekPicks[p].team === awayTeam)
                    return <span className="game_message"><strong>Your pick - {awayTeam}</strong> over {homeTeam}</span>
            }
        }
        
        if (this.hasDeadlinePassed())
            return <span className="game_message">Deadline passed</span>

        return <></>
    }

    isButtonDisabled(team) {
        if (!this.isPickable())
            return true
        else if (team === this.props.currGame.HomeTeam && this.isPicked(this.props.currGame.HomeTeam))
            return true
        else if (team === this.props.currGame.AwayTeam && this.isPicked(this.props.currGame.AwayTeam))
            return true
        else
            return false
    }
    
    renderTeamStyle(team) {
        let result = this.props.teamResults.filter(t => t.team === team)[0].result
        if (result === Results.WIN)
            return <td className="gameteam win_text"><strong>{(isMobile ? "" : `${String.fromCharCode("0x25BA")} `) + team}</strong></td>
        else if (result === Results.LOSS)
            return <td className="gameteam">{team}</td>
        else // result === Results.PENDING
            return <td className="gameteam"><strong>{team}</strong></td>
    }

    renderTeamStandings(team) {
        let result = this.props.teamResults.filter(t => t.team === team)[0].result
        return <td className={result === Results.WIN ? "teamrecord win_text" : "teamrecord"}>{this.formatRecord(team)}</td>

    }

    renderTeamLogo(team) {
        let result = this.props.teamResults.filter(t => t.team === team)[0].result
        return <td className={result === Results.LOSS ? "teamlogo losing_logo" : "teamlogo"}><img alt="logo" src={`/team_logos/teamlogo_${team}.png`} width="30px" height="30px"/></td>
    }

    render() {
        return (
            <div className={"nflgame " + (this.isPickable() ? "" : "notPickable")}>
            {this.formatPickString()}
                <div className="scoreContainer">    
                    <PickButtonContainer
                        awayTeam={this.props.currGame.AwayTeam}
                        homeTeam={this.props.currGame.HomeTeam}
                        awayDisabled={this.isButtonDisabled(this.props.currGame.AwayTeam)}
                        homeDisabled={this.isButtonDisabled(this.props.currGame.HomeTeam)}
                        gameTimeUTC={this.props.currGame.DateTimeUTC}
                    />
                    <table className="scorebox"><tbody>
                    <tr>
                        {this.renderTeamLogo(this.props.currGame.AwayTeam)}
                        {this.renderTeamStyle(this.props.currGame.AwayTeam)}
                        {this.renderTeamStandings(this.props.currGame.AwayTeam)}
                        <td className="gameinfo" rowSpan="2">
                            <strong>{this.formatDate()}</strong><br/>
                            {this.formatTime()}<br/>
                            {this.props.currGame.StadiumDetails.Country !== "USA" ? <><br/><strong className="neutral_site">{this.props.currGame.StadiumDetails.City}</strong><br/></> : ""}
                            <br/>{this.state.pointSpread}
                        </td>
                        {!(isMobile && this.props.pickButtonVisibility === PickButtonVisibilities.BLOCK) &&
                            <td className="pickDeadline" rowSpan="2">
                                {this.props.isOffseason === false &&
                                <>
                                    <strong>Deadline to Pick:</strong><br/>
                                    {this.formatDeadlineToPick()}<br/>
                                </>
                                }
                            </td>
                        }
                    </tr>
                    <tr>
                        {this.renderTeamLogo(this.props.currGame.HomeTeam)}
                        {this.renderTeamStyle(this.props.currGame.HomeTeam)}
                        {this.renderTeamStandings(this.props.currGame.HomeTeam)}
                    </tr>
                    </tbody></table>
                </div>
            </div>
        )
    }
}

// ------- Redux -------
function mapStateToProps(state) {
    return {
        picks: state.picks,
        currentWeek: state.currentWeek,
        currentSeason: state.currentSeason,
        currentEntry: state.currentEntry,
        isOffseason: state.isOffseason,
        pickButtonVisibility: state.pickButtonVisibility,
        teamResults: state.teamResults,
        timezoneOffset: state.timezoneOffset
    }
}

export default connect(mapStateToProps)(Game)