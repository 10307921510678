import React, { Component } from 'react'
import { connect } from 'react-redux'

import _ from 'lodash'
import ReactDOMServer from 'react-dom/server'
import { isMobile } from 'react-device-detect'

import { SET_SHOW_MANUAL_PICK_MODAL, SET_SHOW_EMAIL_REPORT_MODAL, Themes, SET_SHOW_GENERIC_EMAIL_MODAL } from '../../actions/settings'
import { PlayerStatuses, updateEntryPaid, updateBuybackPaid } from '../../actions/actions'

import '../../css/poolview.css'
import ManualPickModal from './ManualPickModal'
import EmailReportModal from './EmailReportModal'
import GenericEmailModal from './GenericEmailModal'
import FinancialDetails from './FinancialDetails'

const MarkAsPaidButtonText = {
    MARK_AS_PAID: "Mark As Paid",
    SAVE: "Save Updates"
}

const lockSize = isMobile ? 21 : 30

class PoolViewTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showElim: true,
            showLogos: true,
            showTeams: false,
            markAsPaidButtonText: MarkAsPaidButtonText.MARK_AS_PAID,
            isMarkPaidMode: false,
            pendingEntriesToMarkPaid: {},
            pendingBuybackToMarkPaid: {},
        }
    }

    handleTableFormat = event => {
        const val = event.target.value
        if (val === "both")
            this.setState({ showLogos: true, showTeams: true})
        else if (val === "logos")
            this.setState({ showLogos: true, showTeams: false})
        else // if (val === "teams")
            this.setState({ showLogos: false, showTeams: true})
    }

    getPickStyleClass(result) {
        switch(result) {
            case "WIN":
                return "pool_view_pick_win"
            case "LOSS":
                return "pool_view_pick_loss"
            default:
                return ""
        }
    }

    formatEntryStatus(status) {
        switch(status) {
            case "ALIVE":
                return PlayerStatuses.ALIVE
            case "ALIVE_BUYBACK":
                return isMobile ? "Alive (BB)" : PlayerStatuses.ALIVE_BUYBACK
            case "ELIMINATED":
                return PlayerStatuses.ELIMINATED
            default:
                return ""
        }
    }

    renderTeamLogo(teamAbbrev) {
        return <img className="pool_view_logo" alt="logo" src={`/team_logos/teamlogo_${teamAbbrev}.png`} width="30px" height="30px"/>
    }

    renderLockIcon() {
        return <span className="lock_cell">
                <img className="lock_icon" src={this.props.theme === Themes.DARK ? "/images/lock_dark.png" : "/images/lock_light.png"} alt="lock icon" width={`${lockSize}px`} height={`${lockSize}px`}/>
            </span>
    }

    renderRow(sUserId, userEntry, isEliminated) {  
        const showLogos = this.state.showLogos
        const showTeams = this.state.showTeams

        let jsx = this.props.poolPicks.filter(p => sUserId === p.sUserId && userEntry === p.userEntry)
            .map(t => {
                // "LOCKED" should not follow normal alphabetization. It should always be last regardless of other string values in array
                // move "LOCKED" to the end of the array
                const sortedTeams = _.sortBy(t.teams)
                sortedTeams.push(sortedTeams.splice(sortedTeams.indexOf("LOCKED"), 1).pop())
                return (
                <td key={t.pickId} className={"week_col " + this.getPickStyleClass(t.aggregatedResult)}>
                    <span className="cell_content">
                        {sortedTeams.map(x => { return <span key={`${t.pickId}-${x}`}>
                                {x === "LOCKED"
                                    ? this.renderLockIcon()
                                    : <>
                                        {showLogos && this.renderTeamLogo(x)}
                                        {showTeams && <span className="pool_view_team">{x}</span>}
                                    </>
                                }
                            </span>
                        })}
                    </span>
                </td> 
            )})

        // logic to account for showing pending cells as locked. Only applies to non-eliminated entries
        if (!isEliminated && this.props.currentWeek + (this.props.currentSeason.includes("POST") ? this.props.numRegularSeasonWeeks : 0) - 1 === jsx.length) {// if there is one less cell than the week number (meaning pick is pending)
            jsx.push(
                <td key={`${this.props.currentWeek}`} className={"week_col " + this.getPickStyleClass(null)}>
                    <span className="cell_content">
                        {this.renderLockIcon()}
                    </span>
                </td>
            )
        }
        
        return jsx
    }

    renderEliminatedTable() {
        if (!this.state.showElim)
            return <></> 

        let jsx = this.props.poolEntries.filter(x => x.status === "ELIMINATED").map(e => {
            const currEntry = this.props.poolEntries.filter(d => d.sUserId === e.sUserId && d.userEntry === e.userEntry)[0]
            return (
            <tr className="entry_row" key={`${e.entryName}`}>
                <td className="pool_view_entry_name">{currEntry.entryName}<br/><span className='userName'>{currEntry.userName}</span></td>
                <td className="pool_view_entry_status">{this.formatEntryStatus(currEntry.status)}</td>
                <td className="pool_view_entry_paid">{this.renderPaidTextOrCheckbox(e.sUserId, e.userEntry, e.paidEntry, false)}</td>
                <td className="pool_view_entry_bb_paid">{this.renderPaidTextOrCheckbox(e.sUserId, e.userEntry, e.paidBuyback, true)}</td>
                {this.renderRow(e.sUserId, e.userEntry, true)}
            </tr>
            )
        })

        // sort the row elements descending based on their length
        return jsx.sort((a, b) => (ReactDOMServer.renderToStaticMarkup(b).length - ReactDOMServer.renderToStaticMarkup(a).length))
    }

    handleEntryPaidCheckboxChange = event => {
        const checkValue = event.target.value.split("-")[1]
        const sUserId = checkValue.split("_")[0]
        const userEntry = checkValue.split("_")[1]

        let obj = { sUserId: sUserId, userEntry: userEntry, changeTo: event.target.checked }
        let curr = this.state.pendingEntriesToMarkPaid
        curr[`${sUserId}-${userEntry}`] = obj
        this.setState({ pendingEntriesToMarkPaid: curr })
    }

    handleBuybackPaidCheckboxChange = event => {
        const checkValue = event.target.value.split("-")[1]
        const sUserId = checkValue.split("_")[0]
        const userEntry = checkValue.split("_")[1]

        let obj = { sUserId: sUserId, userEntry: userEntry, changeTo: event.target.checked }
        let curr = this.state.pendingBuybackToMarkPaid
        curr[`${sUserId}-${userEntry}`] = obj
        this.setState({ pendingBuybackToMarkPaid: curr })
    }

    renderPaidTextOrCheckbox(sUserId, userEntry, isPaid, isBuybackCheckbox) {
        if ((!this.props.isPoolManager && !this.props.isSecondaryManager) || !this.state.isMarkPaidMode) // return text
            return isPaid ? "Y" : ""
        else { // return a checkbox

            // use the value from the pending array if exists, otherwise use isPaid
            const valueFromPendingArray = (isBuybackCheckbox ? this.state.pendingBuybackToMarkPaid[`${sUserId}-${userEntry}`] : this.state.pendingEntriesToMarkPaid[`${sUserId}-${userEntry}`])
            if (typeof valueFromPendingArray != 'undefined' )
                isPaid = valueFromPendingArray.changeTo

            return <input type="checkbox" className="paid_check" key={`paid_${isBuybackCheckbox ? "buyback" : "entry"}_check-${sUserId}_${userEntry}`}
                onChange={isBuybackCheckbox ? this.handleBuybackPaidCheckboxChange : this.handleEntryPaidCheckboxChange}
                checked={isPaid}
                value={`paid_${isBuybackCheckbox ? "buyback" : "entry"}_check-${sUserId}_${userEntry}`}
            />
        }
    }

    // calculate height for pool view table based on mobile vs. desktop and visibility of manager tools
    calculatePoolViewTableHeight() {
        if (!isMobile)
            return 275
        else if (!this.props.isPoolManager && !this.props.isSecondaryManager)
            return 165
        else
            return 240
    }

    renderEntriesTable() {
        return <>
            <div className="pool_view_container" id="pool_view_filters_container">
                <select name="select_table_format" id="select_table_format" onChange={this.handleTableFormat}>
                    <option value="logos">Logos Only</option>
                    <option value="teams">Teams Only</option>
                    <option value="both">Logos and Teams</option>
                </select>
                <><input id="show_elim" key="show_elim" onChange={() => this.setState({ showElim: !this.state.showElim })} type="checkbox" checked={this.state.showElim} value="show_elim" /> Show Eliminated Entries</>
                {this.renderManagerTools()}
            </div>
            
            <div className="pool_view_container" id="pool_view_table_container" style={{height: `calc(100% - (${this.calculatePoolViewTableHeight()}px))`}}>
                <table id="pool_view_table">
                    <tbody>
                        <tr className="header_row">
                            <th className="pool_view_entry_name">Entry</th>
                            <th className="pool_view_entry_status">Status</th>
                            <th className="pool_view_entry_paid">Paid</th>
                            <th className="pool_view_entry_bb_paid">BB Paid</th>
                            { [...Array(parseInt((this.props.currentSeason.includes("POST") ? this.props.numRegularSeasonWeeks : 0) + this.props.currentWeek)).keys()].map((w) => <th key={w} className="week_col week_header">{(w + 1 > this.props.numRegularSeasonWeeks ? `P${w + 1 - this.props.numRegularSeasonWeeks}` : w + 1)}</th>) }
                            {isMobile && <th className="ghost_cell" rowSpan={3 + this.props.poolEntries.length}></th>}
                        </tr>
                        {_.orderBy(this.props.poolEntries.filter(x => x.status !== "ELIMINATED" && x.sUserId === this.props.sUserId), ['entryName']).map(e => {
                            const yourEntry = this.props.poolEntries.filter(d => d.sUserId === e.sUserId && d.userEntry === e.userEntry)[0]
                            return (
                            <tr className="your_entry_row entry_row" key={`${e.entryName}`}>
                                <td>{yourEntry.entryName}<br/><span className='userName'>{yourEntry.userName}</span></td>
                                <td>{this.formatEntryStatus(yourEntry.status)}</td>
                                <td className="pool_view_entry_paid">{this.renderPaidTextOrCheckbox(e.sUserId, e.userEntry, e.paidEntry, false)}</td>
                                <td className="pool_view_entry_bb_paid">{this.renderPaidTextOrCheckbox(e.sUserId, e.userEntry, e.paidBuyback, true)}</td>
                                {this.renderRow(e.sUserId, e.userEntry, false)}
                            </tr>
                            )
                        })
                        }
                        {_.orderBy(this.props.poolEntries.filter(x => x.status !== "ELIMINATED" && x.sUserId !== this.props.sUserId), ['entryName']).map(e => {
                            const currEntry = this.props.poolEntries.filter(d => d.sUserId === e.sUserId && d.userEntry === e.userEntry)[0]
                            return (
                            <tr className="entry_row" key={`${e.entryName}`}>
                                <td>{currEntry.entryName}<br/><span className='userName'>{currEntry.userName}</span></td>
                                <td>{this.formatEntryStatus(currEntry.status)}</td>
                                <td className="pool_view_entry_paid">{this.renderPaidTextOrCheckbox(e.sUserId, e.userEntry, e.paidEntry, false)}</td>
                                <td className="pool_view_entry_bb_paid">{this.renderPaidTextOrCheckbox(e.sUserId, e.userEntry, e.paidBuyback, true)}</td>
                                {this.renderRow(e.sUserId, e.userEntry, false)}
                            </tr>
                            )
                        })
                        }
                        <tr><th className="break_row"></th></tr>
                        {this.renderEliminatedTable()}
                        <tr><th className="break_row" id="bottom_row"></th></tr>
                    </tbody>
                </table>
            </div>
        </>
    }

    renderManagerTools() {
        if (!this.props.isPoolManager && !this.props.isSecondaryManager)
            return <></>

        return <div id="manager_tools">
            {!isMobile && <strong>Manager Tools</strong>}
            {!this.state.isMarkPaidMode && <button className="btn_plain manager_tools_button" onClick={() => this.showManualPickModal()} disabled={!this.props.isBeforeHardDeadline}>Manual Pick Tool</button>}
            {!this.state.isMarkPaidMode && <button className="btn_plain manager_tools_button" onClick={() => this.sendEmailReport()} disabled={this.props.isOffseason || (this.props.currentWeek === this.state.weekToReport && this.props.isBeforeHardDeadline)}>Send Picks Email</button>}
            {!this.state.isMarkPaidMode && <button className="btn_plain manager_tools_button" onClick={() => this.sendGenericEmail()}>Send Generic Email</button>}
            <button className={`manager_tools_button ${this.state.isMarkPaidMode ? "btn_accent" : "btn_plain"}`} onClick={() => this.markAsPaid()}>{this.state.markAsPaidButtonText}</button>
            {this.state.isMarkPaidMode && <button className="btn_plain manager_tools_button" onClick={() => this.resetMarkAsPaid()}>Cancel Updates</button>}
        </div>
    }

    showManualPickModal() {
        this.props.setShowManualPickModal(true)
    }
    
    sendEmailReport() {
        this.props.setShowEmailReportModal(true)
    }

    sendGenericEmail() {
        this.props.setShowGenericEmailModal(true)
    }

    markAsPaid() {
        if (!this.props.isPoolManager && !this.props.isSecondaryManager)
            return

        if (this.state.isMarkPaidMode) {
            // thunks to update both the DB as well as the poolEntries redux
            Object.keys(this.state.pendingEntriesToMarkPaid).map((key) => 
                this.props.updateEntryPaid({
                    sUserId: this.state.pendingEntriesToMarkPaid[key].sUserId,
                    userEntry: this.state.pendingEntriesToMarkPaid[key].userEntry,
                    year: this.props.year,
                    paidEntry: this.state.pendingEntriesToMarkPaid[key].changeTo
                })    
            )

            Object.keys(this.state.pendingBuybackToMarkPaid).map((key) => 
                this.props.updateBuybackPaid({
                    sUserId: this.state.pendingBuybackToMarkPaid[key].sUserId,
                    userEntry: this.state.pendingBuybackToMarkPaid[key].userEntry,
                    year: this.props.year,
                    paidBuyback: this.state.pendingBuybackToMarkPaid[key].changeTo
                })    
            )

            this.resetMarkAsPaid()
        } else {
            this.setState({ isMarkPaidMode: true, markAsPaidButtonText: MarkAsPaidButtonText.SAVE })
        }
    }

    resetMarkAsPaid() {
        this.setState({ isMarkPaidMode: false, markAsPaidButtonText: MarkAsPaidButtonText.MARK_AS_PAID, pendingEntriesToMarkPaid: {}, pendingBuybackToMarkPaid: {} })
    }

    render() {
        return (
            <div id="pool_view">
                <ManualPickModal isPoolManager={this.props.isPoolManager} isSecondaryManager={this.props.isSecondaryManager} scoresByWeek={this.props.scoresByWeek}/>
                <EmailReportModal isPoolManager={this.props.isPoolManager} isSecondaryManager={this.props.isSecondaryManager}/>
                <GenericEmailModal isPoolManager={this.props.isPoolManager} isSecondaryManager={this.props.isSecondaryManager}/>
                <FinancialDetails/>
                {this.renderEntriesTable()}
            </div>
        )
    }
}

// ------- Redux -------
function mapStateToProps(state) {
    return {
        currentSeason: state.currentSeason,
        theme: state.theme,
        currentWeek: state.currentWeek,
        sUserId: state.sUserId,
        picks: state.picks,
        entryFee: state.entryFee,
        buybackAllowed: state.buybackAllowed,
        buybackFee: state.buybackFee,
        numRegularSeasonWeeks: state.numRegularSeasonWeeks,
        poolPicks: state.poolPicks,
        poolEntries: state.poolEntries,
        year: state.year,
        isOffseason: state.isOffseason
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setShowManualPickModal: (response) => dispatch({ type: SET_SHOW_MANUAL_PICK_MODAL, response }),
        setShowEmailReportModal: (response) => dispatch({ type: SET_SHOW_EMAIL_REPORT_MODAL, response }),
        setShowGenericEmailModal: (response) => dispatch({ type: SET_SHOW_GENERIC_EMAIL_MODAL, response }),
        updateEntryPaid: (response) => dispatch(updateEntryPaid(response)),
        updateBuybackPaid: (response) => dispatch(updateBuybackPaid(response))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PoolViewTable)