import React, { Component } from 'react'
import { connect } from 'react-redux'

// import axios from 'axios'
// import EmailSubmitPick from '../emails/EmailSubmitPick'
// import { renderEmail } from 'react-html-email'

import { filterCurrentEntryPicksPendingThisWeek } from '../../utils'

import { 
    submitPick,
    updatePick,
    CLEAR_PENDING_PICKS,
    TOGGLE_PICK_BUTTON_VISIBILITY,
    Results
} from '../../actions/actions'
import { Themes } from '../../actions/settings'

import '../../css/submitmodal.css'
import { isMobile } from 'react-device-detect'

const Headings = {
    CONFIRM: "Confirm Before Submitting",
    SUBMITTED: "Submitted",
    ERROR: "Error"
}

const Messages = {
    SUBMIT_TO: "",
    SUBMITTED: "Your submission has been saved",
    ERROR: "Your pick was not submitted. Please try again later or manually send in your pick to: ",
}

const MainButtonText = {
    SUBMIT: "Submit",
    CLOSE: "Close"
}

class SubmitModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            heading: Headings.CONFIRM,
            message: Messages.SUBMIT_TO,
            mainButtonText: MainButtonText.SUBMIT,
            isSubmitting: false
        }
    }

    resetState() {
        this.setState({
            heading: Headings.CONFIRM,
            message: Messages.SUBMIT_TO,
            mainButtonText: MainButtonText.SUBMIT
        })
    }

    submitPick() {
        // filter picks to just current entry's pending picks for thie current week
        let thisWeeksPicksPending = filterCurrentEntryPicksPendingThisWeek(this.props.picks, this.props.currentEntry, this.props.currentWeek)

        if (thisWeeksPicksPending.length > 0 && this.props.resubmitAllowed) { // if there's an already-submitted pending pick and resubmitting is allowed    
            let pendingPickTeams = thisWeeksPicksPending[0].teams
            let pendingPickResults = thisWeeksPicksPending[0].results
            let pendingPickGameTimes = thisWeeksPicksPending[0].teamGameTimes

            // get indices where the picks are still pending.
            // in indicesToUpdate, if the value is null, the index does not need to be updated because the pick is already settled.
            // in indicesToUpdate, if the value is an integer, that index should be replaced with the pending pick
            let indicesToUpdate = pendingPickTeams.map((x, index) => pendingPickResults[index] === Results.PENDING ? index : null)
            let currentPendingPicks = [...this.props.pendingPick] // clone array. Needed for shift in next line - shift modifies orig array so need copy

            // this combines the already-submitted non-pending picks with the new pending picks, preserving the orig index position of the non-pending pick
            // example: pick contains [TeamA, TeamB]. TeamA has result of WIN, TeamB is pending. TeamA will be kept at index 0, TeamC will replace Team B. [TeamA, TeamC]
            // example: pick contains [TeamA, TeamB]. TeamB has result of WIN, TeamA is pending. TeamB will be kept at index 1, TeamC will replace Team A. [TeamC, TeamB]
            let newTeams = pendingPickTeams.map((x, index) => indicesToUpdate[index] !== null ? currentPendingPicks.shift() : {teamSelected: x, gameTimeUTC: pendingPickGameTimes[index]})

            const promiseResubmit = this.props.updatePick({  // thunk to update a pick at a given index in redux and DB
                sUserId: this.props.sUserId,
                season: this.props.currentSeason,
                userEntry: this.props.currentEntry,
                week: this.props.currentWeek,
                teams: newTeams.map(x => x.teamSelected),
                teamGameTimes: newTeams.map(x => x.gameTimeUTC),
            })

            Promise.all([promiseResubmit]).then(() => {
                this.setState({
                    heading: Headings.SUBMITTED,
                    message: Messages.SUBMITTED,
                    mainButtonText: MainButtonText.CLOSE,
                    isSubmitting: false
                })
                this.props.togglePickButtonVisibility() // pick has been made so hide the buttons again
            })
        } else { // if there is not already a pick submitted, create a new one
            const promiseSubmit = this.props.submitPick({  // add the new pick to the current entry
                sUserId: this.props.sUserId,
                season: this.props.currentSeason,
                pickId: `${this.props.currentSeason}-${this.props.sUserId}-${this.props.currentEntry}-${this.props.currentWeek}`,
                userEntry: this.props.currentEntry,
                week: this.props.currentWeek,
                aggregatedResult: Results.PENDING,
                teams: this.props.pendingPick.map(x => x.teamSelected),
                results: this.props.pendingPick.map(x => Results.PENDING),
                teamGameTimes: this.props.pendingPick.map(x => x.gameTimeUTC),
                isSubmittedMobile: isMobile,
                isAutoAssigned: false
            })

            Promise.all([promiseSubmit]).then(() => {
                this.setState({
                    heading: Headings.SUBMITTED,
                    message: Messages.SUBMITTED,
                    mainButtonText: MainButtonText.CLOSE,
                    isSubmitting: false
                })
                this.props.togglePickButtonVisibility() // pick has been made so hide the buttons again
            })
        }
    }

    mainButtonClicked() {
        if (this.state.mainButtonText === MainButtonText.SUBMIT) {
            this.setState({ isSubmitting: true })
            this.submitPick()
        } else if (this.state.mainButtonText === MainButtonText.CLOSE)
            this.props.clearPendingPicks() // picks no longer pending
        
        this.resetState()
    }

    renderImage() {
        switch (this.state.heading) {
            case (Headings.SUBMITTED):
                return <img className="submit-status-icon" alt="green check" src="/images/greencheck.png" width="35px" height="35px"/>
            case (Headings.ERROR):
                return <img className="submit-status-icon" alt="red x" src="/images/redx.png" width="35px" height="35px"/>
            default:
                return
        }
    }

    renderPicksTable(submittedPendingPicks) {
        // 1. if this is a resubmit and there are unchangeable picks in submittedPendingPicks, show them in the modal first
        // 2. render one row per pending pick
        return <table id="submit_modal_pick_table">
            <thead>
                {submittedPendingPicks.length > 0 && submittedPendingPicks[0].teams.filter((t, index) => submittedPendingPicks[0].results[index] !== Results.PENDING).map(pick =>
                    <tr key={pick}>
                        <th><img alt="logo" src={`/team_logos/teamlogo_${pick}.png`} width="60px" height="60px"/></th>
                        <th>{pick}</th>
                    </tr>
                )}
                {this.props.pendingPick.map(pick =>
                    <tr key={pick.teamSelected}>
                        <th><img alt="logo" src={`/team_logos/teamlogo_${pick.teamSelected}.png`} width="60px" height="60px"/></th>
                        <th>{pick.teamSelected}</th>
                    </tr>
                )}
            </thead>
        </table>
    }
    
    render() {
        let submittedPendingPicks = filterCurrentEntryPicksPendingThisWeek(this.props.picks, this.props.currentEntry, this.props.currentWeek) // get the team(s) picked this week (pending only)  
        let submittedPendingTeams = submittedPendingPicks.length > 0 ? submittedPendingPicks[0].teams.filter((x, index) => submittedPendingPicks[0].results[index] === Results.PENDING) : null
        let showSubmitModal = (this.props.pendingPick.length !== 0 && (submittedPendingPicks.length > 0 ? this.props.pendingPick.length === submittedPendingTeams.length : this.props.pendingPick.length === this.props.teamsPerWeek))
        
        if (!showSubmitModal)
            return <></>
        else {
            const isDark = (this.props.theme === Themes.DARK)
            return (
                <div id="submit_modal" style={showSubmitModal ? {display:"block"} : {display:"none"}}>
                    { this.state.isSubmitting ? 
                        <img id="modal_loading_spinner" src={isDark ? "/loading_dark.gif" : "/loading_light.gif"} alt="loading"/> 
                        :
                        <div id="submit_modal_content">
                            {this.renderImage()}<h2 className="submit-status-heading">{this.state.heading}</h2>
                            <br/><p><strong>Reminder: </strong>A tie is considered a loss.</p>
                            {this.props.teamsPerWeek > 1 && <><br/><p><strong>Reminder: </strong>Each team below must win in order for you to get a win this week.</p></>}<br/>
                            <p><strong>Entry: </strong>{this.props.entries[this.props.currentEntry].entryName}</p>
                            {this.renderPicksTable(submittedPendingPicks)}
                            <p>{this.state.message}</p>
                            <div id="btn_modal_container">
                                <button className="btn_plain btn_modal" id="btn_cancel_modal" onClick={() => this.props.clearPendingPicks()} style={this.state.mainButtonText === "Close" ? {display:"none"} : {display:"block"}}>Go Back</button>
                                <button className="btn_accent btn_modal" id="btn_submit_modal" onClick={() => this.mainButtonClicked()}>{this.state.mainButtonText}</button>
                            </div>
                        </div>
                    }
                </div>
            )
        }
    }
}

// ------- Redux -------
function mapStateToProps(state) {
    return {
        sUserId: state.sUserId,
        pendingPick: state.pendingPick,
        currentWeek: state.currentWeek,
        currentSeason: state.currentSeason,
        picks: state.picks,
        currentEntry: state.currentEntry,
        entries: state.entries,
        teamsPerWeek: state.teamsPerWeek,
        theme: state.theme,
        resubmitAllowed: state.resubmitAllowed
    }
}

const mapDispatchToProps = dispatch => {
    return {
        submitPick: (response) => dispatch(submitPick(response)),
        updatePick: (response) => dispatch(updatePick(response)),
        clearPendingPicks: () => dispatch({ type: CLEAR_PENDING_PICKS }),
        togglePickButtonVisibility: () => dispatch({ type: TOGGLE_PICK_BUTTON_VISIBILITY })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubmitModal)