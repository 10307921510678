import React, { Component } from 'react'
import { connect } from 'react-redux'
import { isMobile } from 'react-device-detect'

import axios from 'axios'

import { filterCurrentEntryPicksPendingThisWeek, filterCurrentEntryPicksThisWeek } from '../../utils'

import {
    SET_PICK_BUTTON_VISIBILITY,
    PlayerStatuses, 
    PickButtonVisibilities,
    CLEAR_PENDING_PICKS,
    BuybackChoices,
    Results
} from '../../actions/actions'
import { Themes, SET_SHOW_BUYBACK_MODAL } from '../../actions/settings'

import Game from './Game'
import PoolWinner from './PoolWinner'

import '../../css/gameslist.css'

const SubmitButtonText = {
    MAKE_PICK: "Make a Pick",
    CANCEL_PICK: "Cancel Pick",
    CANCEL_PICK_MOBILE: "Cancel"
}

class GamesList extends Component {
    constructor(props) {
        super(props)
        this.hasDeadlinePassed = this.hasDeadlinePassed.bind(this)
        this.state = { 
            scoresByWeek: null,
            hardDeadline: null,
            standings: null,
            spreads: null,
            isReady: false,
            isDeadlinePassed: false
        }
    }
    
    componentDidMount() {
        let hardDeadline = null

        // get the dynamic data from the DB
        const promiseData = axios.get("/api/api_data")
        .then((response) => {
            this.setState({ 
                scoresByWeek: response.data[0].scoresByWeek,
                hardDeadline: response.data[0].hardDeadline,
                standings: response.data[0].standings,
                spreads: response.data[0].spreads,
                teamResults: response.data[0].teamResults
            })
            hardDeadline = response.data[0].hardDeadline
        })
        .catch(() => { console.log("Api data - Internal server error") })

        Promise.all([promiseData]).then(() => {
            this.setState({ isReady: true })

            // refresh every second to see if deadline has passed
            let currDateTime = new Date()
            let hardDeadlineDate = new Date(hardDeadline)

            if (currDateTime.getDay() === hardDeadlineDate.getDay() && currDateTime.getHours() === hardDeadlineDate.getHours()) { // only on Sundays 1:00pm hr do we need to check for deadline
                this.hasDeadlinePassed()
                setInterval(this.hasDeadlinePassed, 1000)
            }
        })   
    }

    async hasDeadlinePassed() {
        try {
            const currDateTime = new Date()
            const currMin = currDateTime.getMinutes()
            this.setState({ isDeadlinePassed: (currMin >= 0 && currMin <= 5) }) // window of 1:00-1:05 - force disable the button to ensure server has time
        } catch (e) {
            console.log(e)
        }
    }

    // get the current entry's buyback choice
    getBuybackChoice() {
        let currentEntryObj = this.props.entries.find(e => e.userEntry === this.props.currentEntry)
        if (currentEntryObj && this.props.entries && this.props.entries.length > 0)
            return currentEntryObj.buybackChoice
        else   
            return ""
    }

    // get the current entry's status
    getCurrentEntryStatus() {
        if (this.props.entries.length === 0)
            return ""
        let p = this.props.entries.filter(e => e.userEntry === this.props.currentEntry)
        return (p.length > 0 ? p[0].status : "")
    }

    // format the current entry's status as a displayable string
    getStatusString() {
        let buybackChoice = this.getBuybackChoice()
        if (buybackChoice === BuybackChoices.IGNORE || buybackChoice === BuybackChoices.PENDING)
            return "Pending Buyback"
        
        switch(this.getCurrentEntryStatus()) {
            case "ALIVE":
                return PlayerStatuses.ALIVE
            case "ALIVE_BUYBACK":
                return PlayerStatuses.ALIVE_BUYBACK
            case "ELIMINATED":
                return PlayerStatuses.ELIMINATED
            default:
                return ""
        }
    }
    
    submitButtonClicked(text) {
        let showBuybackModal = (this.getBuybackChoice() === BuybackChoices.IGNORE)
        this.props.setShowBuybackModal(showBuybackModal)
        
        let isCancelPick = (text === SubmitButtonText.CANCEL_PICK || text === SubmitButtonText.CANCEL_PICK_MOBILE)      
        if (isCancelPick)
            this.props.clearPendingPicks()
        this.props.setPickButtonVisibility(isCancelPick ? PickButtonVisibilities.NONE : PickButtonVisibilities.BLOCK)
    }

    // render the button based on whether the player is stil alive and whether this week's pick has been submitted
    renderSubmitButton() {
        let statusString = this.getStatusString()
        let allowSubmit = false
        let btnText = ""
        
        if (statusString === PlayerStatuses.ELIMINATED) // if player is eliminated, show eliminated
            btnText = statusString
        else if (this.props.pickButtonVisibility === PickButtonVisibilities.BLOCK) {
            btnText = (isMobile ? SubmitButtonText.CANCEL_PICK_MOBILE : SubmitButtonText.CANCEL_PICK)
            allowSubmit = true
        } else {
            // see if they submitted already
            let p = filterCurrentEntryPicksThisWeek(this.props.picks, this.props.currentEntry, this.props.currentWeek, this.props.currentSeason) // filter picks to just current entry's picks
            let pickSubmitted = (p.length > 0)
            btnText = SubmitButtonText.MAKE_PICK

            if (this.props.resubmitAllowed) { // if resubmitting is allowed and already submitted, allow them to change pick    
                allowSubmit = this.props.entries.length !== 0 && !this.state.isDeadlinePassed
                if (pickSubmitted) {
                    // get the team(s) picked this week (Pending only. You cannot change a pick that already has a result)
                    let thisWeeksPick = p.filter(pick => pick.week === this.props.currentWeek && pick.aggregatedResult === Results.PENDING)
                    if (!thisWeeksPick || thisWeeksPick.length === 0) { // if no pending teams, don't allow submit
                        allowSubmit = false
                    } else {
                        let thisWeeksTeams = thisWeeksPick[0].teams
                        let thisWeeksResults = thisWeeksPick[0].results
                        let pendingTeams = thisWeeksTeams.filter((x, index) => thisWeeksResults[index] === Results.PENDING)
                        if (this.props.isOffseason) { // if offseason can always change pick. This happens if a pick is submitted before Week 1 begins
                            allowSubmit = true
                        } else {
                            allowSubmit = false // start by assuming all teams picked this week are unchangeable
                            for (let team in pendingTeams) { // check each team
                                for (let game in this.state.scoresByWeek) { // for every game, see if the team is involved
                                    if (pendingTeams[team] === this.state.scoresByWeek[game].AwayTeam || pendingTeams[team] === this.state.scoresByWeek[game].HomeTeam) {
                                        if (!this.state.scoresByWeek[game].HasStarted) { // once game is found, see if the game has started
                                            var gameDate = new Date(this.state.scoresByWeek[game].Date)
                                            var currDate = new Date()
                                            // extra check: if HasStarted === false, make sure that game didn't actually start by comparing hours if game date is today
                                            if (currDate.getDay() !== gameDate.getDay() || (currDate.getDay() === gameDate.getDay() && currDate.getHours() < gameDate.getHours())) {
                                                allowSubmit = true // if the game hasn't started, you can change your pick
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        btnText = "Change Pick" + (pendingTeams.length > 1 ? "s" : "")
                        if (!isMobile) {
                            if (pendingTeams.length === 0) // if there are no pending teams, it means the game(s) have already gone final so get this weeks picks regardless of result
                                pendingTeams = p.find(pick => pick.week === this.props.currentWeek).teams
                            btnText += ": " + pendingTeams[0] // always will be at least one pick
                            for (var i = 1; i < pendingTeams.length; i++) // get any subsequent picks, formatted with comma and space
                                btnText += ", " + pendingTeams[i]
                        }
                    }
                } 
            } else { // if resubmitting not allowed, check to see if they submitted already            
                allowSubmit = !pickSubmitted && this.props.entries.length !== 0 && !this.state.isDeadlinePassed
                if (pickSubmitted) {
                    btnText = ""
                    if (!isMobile)
                        btnText = "Submitted: " 
                    btnText += p[p.length - this.props.teamsPerWeek].team // always will be at least one pick
                    for (var j = p.length - this.props.teamsPerWeek + 1; j < p.length; j++) // get any subsequent picks, formatted with comma and space
                        btnText += ", " + p[j].team
                }
            }
        }

        return (
            <button id='btn_make_pick' className={this.props.pickButtonVisibility === PickButtonVisibilities.BLOCK ? "btn_plain" : "btn_accent"} disabled={!allowSubmit}
                onClick={() => this.submitButtonClicked(btnText)}>{btnText}
            </button>
        )
    }

    renderTeamsPerWeekMessage() {
        let thisWeeksPicks = filterCurrentEntryPicksPendingThisWeek(this.props.picks, this.props.currentEntry, this.props.currentWeek) // get the team(s) picked this week (pending only) for this entry
        //let isResubmit = (thisWeeksPicks.length > 0) // TODO: need to use this flag to control message logic - new pick vs resubmit. If new pick - logic should follow what's on master. Else, logic below. Then consolidate

        var msg = ""
        if ((thisWeeksPicks.length !== 0 && this.props.pendingPick.length === thisWeeksPicks[0].teams.length) || this.getCurrentEntryStatus().toUpperCase() === PlayerStatuses.ELIMINATED.toUpperCase())
            return // if pending picks equal the number of picks that are supposed to be picked, or entry is eliminated, don't display a msg
        else {
            if (this.props.pendingPick.length > 0) { // if there are pending picks but not the max number of pending picks possible 
                msg = this.props.pendingPick[0].teamSelected
                for (var i = 1; i < this.props.pendingPick.length; i++)
                    msg += ", " + this.props.pendingPick[i].teamSelected
                msg += ` selected. Pick ${this.props.teamsPerWeek - this.props.pendingPick.length} more.` 
            } else { // there are no pending picks at this time, so just show the normal message
                if (!isMobile && thisWeeksPicks.length !== 0 && this.props.teamsPerWeek > thisWeeksPicks.length) 
                    msg = `Change pending picks`
                else
                    msg = `Pick ${this.props.teamsPerWeek} team${this.props.teamsPerWeek > 1 ? "s" : ""}${isMobile ? "." : " this week."}`
            }
        }
        return msg
    }

    render() {
        const isDark = (this.props.theme === Themes.DARK)
        if (!this.state.isReady) { // show loading spinner while data loads
            return (
                <div id="loading" className={isDark ? "darkTheme" : "lightTheme"}>
                    <img id="loading_spinner" src={isDark ? "/loading_dark.gif" : "/loading_light.gif"} alt="loading"/>
                </div>
            )
        } else {
            return (
                <div id="games">
                    <div id="gamebar">
                        <div className="game_status">{!isMobile && <strong>Status: </strong>}{this.getStatusString()}</div> 
                        {this.renderSubmitButton()}
                        <div className="game_status" id="picks_per_week_message">{this.renderTeamsPerWeekMessage()}</div>
                    </div>
                    <br/><br/>
                    {this.props.poolWinners?.length > 0 && <PoolWinner/>}
                    {this.state.scoresByWeek?.length > 0 ? 
                        this.state.scoresByWeek.map((currGame) =>       
                            <Game 
                                key={currGame.GameKey} 
                                currGame={currGame} 
                                standings={this.state.standings}
                                spreads={this.state.spreads}
                            />
                        ) 
                    :
                        <div id="zero_games">No games to display</div>
                    }
                    <br/><br/><br/><br/>
                </div>
            )
        }
    }
}

// ------- Redux -------
function mapStateToProps(state) {
    return {
        picks: state.picks,
        currentWeek: state.currentWeek,
        currentSeason: state.currentSeason,
        entries: state.entries,
        currentEntry: state.currentEntry,
        pickButtonVisibility: state.pickButtonVisibility,
        teamsPerWeek: state.teamsPerWeek,
        pendingPick: state.pendingPick,
        theme: state.theme,
        resubmitAllowed: state.resubmitAllowed,
        poolWinners: state.poolWinners,
        isOffseason: state.isOffseason
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setPickButtonVisibility: (response) => dispatch({ type: SET_PICK_BUTTON_VISIBILITY, response }),
        clearPendingPicks: () => dispatch({ type: CLEAR_PENDING_PICKS }),
        setShowBuybackModal: (response) => dispatch({ type: SET_SHOW_BUYBACK_MODAL, response })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GamesList)